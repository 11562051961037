.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 40px;
  height: 120px;

  display: flex;

  .client-login {
    div {
      padding: 0;
      margin: 13px 0;
      cursor: pointer;
      width: fit-content;
    }
  }
  .gap {
    flex: 1;
    padding: 0px;
  }
  div {
    padding: 20px;
  }
  .office-div {
    p {
      font-size: 13px;
    }
    .time {
      color: rgb(165, 165, 165);
      font-size: 18px;
      margin-top: -10px;
    }
  }

  @media only screen and (max-width: 900px) {
    right: 0px;
    .client-login {
      max-width: 70px;
    }
    .office-div {
      max-width: 50px;
    }
  }
}
