.reset-page {
  right: 0;
  bottom: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;

  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    width: 300px;
    position: relative;
    p {
      margin-left: -30px;
      font-family: 'GT-Cinetype-Light';
      font-weight: 100;
    }
    .error-message {
      color: red;
      width: 370px;
      position: absolute;
      left: 0;
      top: -100px;
    }
    input {
      width: 100%;
      margin-bottom: 20px;
      background: transparent;
      outline: none;
      color: white;
      font-size: 14px;
      padding: 5px;
      box-sizing: border-box;
      border: 1px solid rgb(150, 150, 150);
    }
    .control-div {
      display: flex;
      width: 100%;
      margin-top: -10px;
      span {
        flex: 1;
      }
      img {
        width: 23px;
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .content {
      p {
        margin-left: 0;
      }
      .error-message {
        width: 300px;
      }
    }
  }
}
