.home-page {
  right: 0;
  bottom: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;

  .background-video-container {
    video {
      z-index: -1;
      object-fit: cover;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .header-container {
    position: absolute;
    left: 30px;
    top: 70px;
    .header {
      font-size: 28px;
    }
    .subheader {
      margin: 5px 5px 0 0;
      font-size: 28px;
      color: rgb(212, 212, 212);
      font-family: 'GT-Cinetype-Light';
      font-weight: 100;
    }
  }
}
